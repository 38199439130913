<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
//import store from "@/store";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["getConsultant", "getUser"]),
  },
  methods: {
    ...mapActions(["setConsultant", "setUser", "setApps","setAppsDefault"]),
    ...mapMutations([]),
  },
  created() {
    this.setAppsDefault();
  },
  async mounted() {
    document.addEventListener("DOMContentLoaded", async () => {
      // Get Auth User
      

      const odooData = document.querySelector("#auth").dataset;
      // document.querySelector("#auth").remove();
      const user = {
        email: odooData?.email.toLowerCase(),
        password: odooData?.pwd,
      };
      const consultant = {
        name: odooData?.name,
        services: Number(odooData?.services),
        position: odooData?.position,
        club: odooData?.club,
      };
      const apps = {
        usell: odooData?.usell == "true" ? true : false,
        uhub: odooData?.uhub == "true" ? true : false,
        ucall: odooData?.ucall == "true" ? true : false,
        umail: odooData?.umail == "true" ? true : false,
        uacademy: odooData?.uacademy == "true" ? true : false,
        umag: odooData?.umag == "true" ? true : false,
      };
      
      if (user) {
        await this.setUser(user);
      }
      if (apps) {
        await this.setApps(apps);
      }
      if (consultant) {
        await this.setConsultant(consultant);
      }
    });
  },
};
</script>
