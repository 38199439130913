<template>
  <v-card
    class="text-center"
    elevation="0"
    style="background-color: transparent"
  >
    <v-avatar
      class="ma-3"
      size="125"
      tile
      v-if="
        consultant.position &&
        positions.includes(consultant.position.toLowerCase())
      "
    >
      <v-img
        :src="`https://odoo.u-pass.app/${consultant.position.toLowerCase()}.icon.png`"
      ></v-img>
    </v-avatar>
    <v-card-title class="text-center text-h4 d-block text-wrap">
      Bienvenue {{ consultant.name }},
    </v-card-title>

    <v-card-subtitle
      ><h5 class="mt-2">
        <span class="font-small-3"
          >Vous êtes Agent<span
            :class="consultant.position.toLowerCase()"
            v-if="consultant.position"
          >
            {{ consultant.position }}</span
          >
          du club : {{ consultant.club }}</span
        >
      </h5></v-card-subtitle
    >
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return { positions: ["bronze", "silver", "gold", "platinium", "diamond"] };
  },
  computed: {
    ...mapGetters({
      consultant: "getConsultant",
    }),
  },
};
</script>

<style scoped>
.card-congratulations {
  /*background: linear-gradient(118deg, #e43569, rgba(228, 53, 105, 0.7));*/
  background: transparent !important;
  border: none;
  width: 100%;
  position: relative;
}
.match-height > [class*="col"] > .card {
  flex: 1 1 auto;
}
.card-congratulations .congratulations-img-left {
  left: 0;
}
.card-congratulations .congratulations-img-left {
  width: 200px;
  position: absolute;
  top: 0;
}
.card-congratulations .congratulations-img-right {
  right: 0;
}
.card-congratulations .congratulations-img-right {
  width: 175px;
  position: absolute;
  top: 0;
}

.gold {
  color: gold;
}
.silver {
  color: silver;
}
.bronze {
  color: #cd7f32;
}
.platinium {
  color: #e5e4e2;
}
.smile {
  color: pink;
}
.diamond {
  color: #9ac5db;
}
.bg-gold {
  background-color: gold !important;
}
.bg-silver {
  background-color: silver !important;
}
.bg-bronze {
  background-color: #cd7f32 !important;
}
.bg-platinium {
  background-color: #e5e4e2 !important;
}
.bg-smile {
  background-color: pink !important;
}
.bg-diamond {
  background-color: #9ac5db !important;
}
.congratulation-medal {
  right: 37px;
}
.congratulation-medal {
  position: absolute;
  top: 0;
}
.match-height > [class*="col"] > .card {
  flex: 1 1 auto;
}
</style>
