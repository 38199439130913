<template>
  <v-container
    fluid
    class="d-flex flex-column justify-around align-stretch"
    style="height: 100%"
  >
    <v-row justify="center" align="center">
      <v-col
        lg="8"
        md="12"
        cols="12"
        class="right"
        style="background: transparent"
      >
        <div
          :class="{
            'custom-border':
              consultant.position &&
              positions.includes(consultant.position.toLowerCase()),
            'default-border':
              !consultant.position ||
              !positions.includes(consultant.position.toLowerCase()),
          }"
          :style="
            consultant.position &&
            positions.includes(consultant.position.toLowerCase())
              ? `
            -moz-border-image: url(https://odoo.u-pass.app/${
              isMobile
                ? consultant.position.toLowerCase()
                : consultant.position.toLowerCase() + '.min'
            }.png) 50 50 50 50  ;
            -webkit-border-image: url(https://odoo.u-pass.app/${
              isMobile
                ? consultant.position.toLowerCase()
                : consultant.position.toLowerCase() + '.min'
            }.png) 50 50 50 50  ;
            -o-border-image: url(https://odoo.u-pass.app/${
              isMobile
                ? consultant.position.toLowerCase()
                : consultant.position.toLowerCase() + '.min'
            }.png) 50 50 50 50  ;
            border-image: url(https://odoo.u-pass.app/${
              isMobile
                ? consultant.position.toLowerCase()
                : consultant.position.toLowerCase() + '.min'
            }.png) 50 50 50 50  ;
            
            z-index: 1000;`
              : 'border-image:url(https://odoo.u-pass.app/none.png) 380 380 380 380 fill;'
          "
        >
          <front-stats-left ref="left" class="p-3" />
        </div>
      </v-col>
    </v-row>
    <v-row class="align-self-center">
      <front-apps />
    </v-row>
  </v-container>
</template>

<script>
/*eslint-disable*/
import FrontStatsLeft from "../components/FrontStatsLeft.vue";
import FrontApps from "../components/FrontApps.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    FrontStatsLeft,
    FrontApps,
  },
  data() {
    return {
      positions: ["bronze", "silver", "gold", "platinium", "diamond"],
    };
  },
  mounted() {
    let self = this;
  },
  computed: {
    ...mapGetters({
      consultant: "getConsultant",
    }),
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-style: solid;
  border-width: 50px 50px 50px 50px;
}
.default-border {
  border-style: solid;
  border-width: 50px 50px 50px 50px;
}
</style>
