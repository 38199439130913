import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

// libraries
import CryptoJS from "crypto-js";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.DEV,
  state: {
    user: null,
    apps: {},
    credentials: null,
    consultant: null,
  },
  actions: {
    encryptData({ state }, app) {
      return new Promise((resolve) => {
        let user = state.user;
        user.timestamp = new Date();
        let encrypted;
        if (app.name != "umail") {
          encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(user),
            "nekedhbou 3la b3adhna imed"
          ).toString();
        } else {
          let ct = CryptoJS.AES.encrypt(
            JSON.stringify(user),
            "nekedhbou 3la b3adhna imed"
          );
          //var saltHex = ct.salt.toString();     // random salt
          var ctHex = ct.ciphertext.toString(); // actual ciphertext
          var ivHex = ct.iv.toString();
          var key = ct.key.toString(); // generated IV

          encrypted = [key, ivHex, ctHex].join("m");
        }
        resolve(encrypted);
      });
    },
    setAppsDefault({ commit }) {
      commit("setApps", {
        usell: {
          name: "usell",
          id: 1,
          permission: false,
          url: "https://v2.u-sell.app/login",
          logo: "https://u-smile.fra1.digitaloceanspaces.com/upassicons/usell.png",
          type: "external",
        },
        uhub: {
          name: "uhub",
          id: 2,
          permission: false,
          url: "https://v2.u-sell.app/login",
          logo: "https://u-smile.fra1.digitaloceanspaces.com/upassicons/uhub.png",
          type: "external",
        },
        umail: {
          name: "umail",
          id: 3,
          permission: false,
          url: "https://u-pass.app/api/users/auto-login-mail",
          logo: "https://u-smile.fra1.digitaloceanspaces.com/upassicons/umail.png",
          type: "external",
        },
        //ulearn: {
          //name: "ulearn",
          //id: 4,
          //permission: true,
          //url: "https://u-smile.app/slides/all",
          //logo: "https://odoo.u-pass.app/img/ulearn_new.7d866a0b.jpeg",
          //type: "internal",
        //},
        myusmile: {
          name: "myusmile",
          id: 5,
          permission: true,
          url: "https://u-smile.app/web",
          logo: "https://u-smile.fra1.digitaloceanspaces.com/upassicons/usmileapp.png",
          type: "internal",
        },
         uacademy: {
           name: "uacedemy",
           id: 6,
           permission: false,
           url: "https://u-academy.app/login",
           logo: "https://u-smile.fra1.digitaloceanspaces.com/upassicons/uacademy.png",
           type: "external",
         },
        umag: {
          name: "umag",
          id: 7,
          permission:false,
          url: "https://v1.u-mag.u-smile.app/login",
          logo: "https://u-smile.fra1.digitaloceanspaces.com/upassicons/umag.png",
          type: "external",
        },
      });
    },
    setApps({ commit }, apps) {
      return new Promise((resolve) => {
        for (const app in apps) {
          
          if (apps[app]) {
            commit("grantAccess", app);
          }
        }
        resolve(true);
      });
    },
    setConsultant({ commit }, consultant) {
      return new Promise((resolve) => {
        commit("setConsultant", consultant);
        resolve(true);
      });
    },
    setUser({ commit }, user) {
      return new Promise((resolve) => {
        commit("setUser", user);
        resolve(true);
      });
    },
  },
  mutations: {
    grantAccess(state, appId) {      
      if(appId && state.apps[appId])
      state.apps[appId].permission = true;
    },
    setConsultant(state, consultant) {
      state.consultant = consultant;
    },
    setUser(state, user) {
      state.user = user;
    },
    setApps(state, apps) {
      state.apps = apps;
    },
  },
  getters: {
    getConsultant(state) {
      return state.consultant;
    },
    getQrCode(state) {
      let user = state.user;
      user.timestamp = new Date();
      return CryptoJS.AES.encrypt(
        JSON.stringify(user),
        "nekedhbou 3la b3adhna imed"
      ).toString();
    },
    getUser(state) {
      return state.user;
    },
    getApps(state) {
      return state.apps;
    },
  },
  plugins: [
    createPersistedState({
      key: "u-sell",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
