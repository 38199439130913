<template>
  <v-item-group>
    <v-container fluid>
      <v-row justify="start">
        <v-col v-for="app in apps" :key="app.id">
          <v-item v-slot="{ active, toggle }">
            <div
              :color="active ? 'primary' : ''"
              class="d-flex align-center justify-center"
              style="padding: 0px"
              @click="toggle"
            >
              <a
                v-if="app.type == 'external'"
                @click.prevent="goToApp(app)"
                :href="!app.permission ? '#' : app.url"
                :target="!app.permission ? '_self' : '_blank'"
                :disabled="!app.permission"
                
              >
                <v-img
                  v-if="app.logo"
                  :src="app.logo"
                  :class="{ disabled: !app.permission }"
                  width="10rem"
                />
                <div
                  style="
                    width: 10rem;
                    height: 10rem;
                    background-color: aliceblue;
                    border-radius: 20%;
                  "
                  v-else
                  class="d-flex align-center justify-center"
                  :class="{ disabled: !app.permission }"
                >
                  {{ app.name }}
                </div>
              </a>
              <a
                v-else
                :href="app.url"
                target="_blank"
                :disabled="!app.permission"
              >
                <v-img
                  :src="app.logo"
                  :class="{ disabled: !app.permission }"
                  width="10rem"
                />
              </a>
            </div>
          </v-item>
        </v-col>
        <v-col key="qr_code">
          <v-item>
            <div
              class="d-flex align-center justify-center"
              style="padding: 0px"
            >
              <a target="_blank">
                <div
                  style="
                    width: 10rem;
                    height: 10rem;
                    background-color: rgb(204, 204, 204, 25);
                    border-radius: 15%;
                  "
                  class="p-3"
                >
                  <v-img :src="qr_code" width="8rem" height="8rem" />
                </div>
              </a>
            </div>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  // eslint-disable-next-line vue/no-unused-components
  data() {
    return {
      clicked: "",
      qr_code: "",
    };
  },
  computed: {
    ...mapGetters({
      apps: "getApps",
      getQrCode: "getQrCode",
    }),
  },
  mounted() {
    this.$axios
      .post("https://dev.api.u-sell.app/api/generate-qr-code", {
        code: this.getQrCode,
      })
      .then((res) => {
        this.qr_code = res.data;
      });
  },
  methods: {
    ...mapActions(["encryptData"]),
    async goToApp(app) {
      let hash = await this.encryptData(app);
      let url = app.permission ? app.url + "?n3bi=" + hash : "#";
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.click();
    },
  },
};
</script>

<style>
.disabled {
  filter: grayscale(100%);
}
.test {
  background-color: "red";
}
.qr_code svg {
  height: ;
}
</style>
