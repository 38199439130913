var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column justify-around align-stretch",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"right",staticStyle:{"background":"transparent"},attrs:{"lg":"8","md":"12","cols":"12"}},[_c('div',{class:{
          'custom-border':
            _vm.consultant.position &&
            _vm.positions.includes(_vm.consultant.position.toLowerCase()),
          'default-border':
            !_vm.consultant.position ||
            !_vm.positions.includes(_vm.consultant.position.toLowerCase()),
        },style:(_vm.consultant.position &&
          _vm.positions.includes(_vm.consultant.position.toLowerCase())
            ? ("\n          -moz-border-image: url(https://odoo.u-pass.app/" + (_vm.isMobile
              ? _vm.consultant.position.toLowerCase()
              : _vm.consultant.position.toLowerCase() + '.min') + ".png) 50 50 50 50  ;\n          -webkit-border-image: url(https://odoo.u-pass.app/" + (_vm.isMobile
              ? _vm.consultant.position.toLowerCase()
              : _vm.consultant.position.toLowerCase() + '.min') + ".png) 50 50 50 50  ;\n          -o-border-image: url(https://odoo.u-pass.app/" + (_vm.isMobile
              ? _vm.consultant.position.toLowerCase()
              : _vm.consultant.position.toLowerCase() + '.min') + ".png) 50 50 50 50  ;\n          border-image: url(https://odoo.u-pass.app/" + (_vm.isMobile
              ? _vm.consultant.position.toLowerCase()
              : _vm.consultant.position.toLowerCase() + '.min') + ".png) 50 50 50 50  ;\n          \n          z-index: 1000;")
            : 'border-image:url(https://odoo.u-pass.app/none.png) 380 380 380 380 fill;')},[_c('front-stats-left',{ref:"left",staticClass:"p-3"})],1)])],1),_c('v-row',{staticClass:"align-self-center"},[_c('front-apps')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }